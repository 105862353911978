import React from 'react'

import {
  StyledQuickLinks,
  StyledQuickLinksTitle,
  StyledQuickLinksList,
  StyledQuickLinksItem,
  StyledQuickLink,
} from './styled'

const QuickLinks = () => (
  <StyledQuickLinks>
    <StyledQuickLinksTitle>Quick Links</StyledQuickLinksTitle>
    <StyledQuickLinksList>
      <StyledQuickLinksItem>
        <StyledQuickLink href="#minors">Minors</StyledQuickLink>
      </StyledQuickLinksItem>

      <StyledQuickLinksItem>
        <StyledQuickLink href="#photos-other-user-generated-content">
          Photos and Other User Generated Content
        </StyledQuickLink>
      </StyledQuickLinksItem>

      <StyledQuickLinksItem>
        <StyledQuickLink href="#harassment">Harassment</StyledQuickLink>
      </StyledQuickLinksItem>

      <StyledQuickLinksItem>
        <StyledQuickLink href="#unicorn-hunting">
          Unicorn Hunting
        </StyledQuickLink>
      </StyledQuickLinksItem>

      <StyledQuickLinksItem>
        <StyledQuickLink href="#reporting-profile">
          Reporting a Profile
        </StyledQuickLink>
      </StyledQuickLinksItem>

      <StyledQuickLinksItem>
        <StyledQuickLink href="#dating-safety-tips">
          Dating Safety Tips
        </StyledQuickLink>
      </StyledQuickLinksItem>
    </StyledQuickLinksList>
  </StyledQuickLinks>
)

export default QuickLinks
