import React from 'react'
import QuickLinks from './QuickLinks'
import Guidelines from './Guidelines'
import { StyledContent } from './styled'

const CommunityGuidelinesPage = () => (
  <StyledContent>
    <QuickLinks />
    <Guidelines />
  </StyledContent>
)

export default CommunityGuidelinesPage
