import styled from 'styled-components'
import { theme } from 'common/styles'

const StyledGuidelines = styled.div`
  display: grid;
  gap: 5.6rem;
`

const StyledGuideline = styled.div`
  display: grid;
  gap: 2.4rem;
  color: #41354b;

  ${theme.mq.large.css`
    gap: 3.2rem;
  `}
`

const StyledGuidelineTitle = styled.h2`
  margin: 0;
  font-size: 3.2rem;
  line-height: 3.2rem;

  ${theme.mq.large.css`
    font-size: 4rem;
    line-height: 4rem;
  `}
`

const StyledGuidelineText = styled.p`
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.8rem;

  strong {
    font-weight: 700;
  }

  a {
    color: #41354b;
  }

  ${theme.mq.large.css`
    font-size: 1.8rem;
    line-height: 3.2rem;
  `}
`

const StyledGuidelineTextWithLink = styled.p`
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.8rem;

  strong {
    font-weight: 700;
  }

  a {
    color: #9f2ffe;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  ${theme.mq.large.css`
    font-size: 1.8rem;
    line-height: 3.2rem;
  `}
`

const StyledGuidelineTextTitle = styled.h3`
  margin: 0;
  font-size: 2.1rem;
  line-height: 3.15rem;

  ${theme.mq.large.css`
    font-size: 2.4rem;
    line-height: 3.6rem;
  `}
`

const StyledGuidelineTextList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
  line-height: 2.8rem;

  ${theme.mq.large.css`
    font-size: 1.8rem;
    line-height: 3.2rem;
  `}
`

const StyledGuidelineTextListItem = styled.li`
  margin: 0;

  a {
    color: inherit;
  }
`

export {
  StyledGuidelines,
  StyledGuideline,
  StyledGuidelineTitle,
  StyledGuidelineText,
  StyledGuidelineTextWithLink,
  StyledGuidelineTextTitle,
  StyledGuidelineTextList,
  StyledGuidelineTextListItem,
}
