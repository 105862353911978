import styled from 'styled-components'

const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 106.4rem;
  margin: 9rem auto 0;
  padding: 0 2rem;

  ${props => props.theme.mq.small.css`
    padding: 0 2rem 2rem;
  `}
`

const StyledHeading = styled.h1`
  margin: 0;
  width: min-content;
  font-size: 8rem;
  font-weight: bold;

  ${props => props.theme.mq.small.css`
    margin: 0 auto;
    font-size: clamp(4rem, 15vw, 5.6rem);
    text-align: center;
   `}
`

export { StyledContainer, StyledHeading }
