import React from 'react'
import { Link } from 'gatsby'

import {
  StyledGuidelines,
  StyledGuideline,
  StyledGuidelineTitle,
  StyledGuidelineText,
  StyledGuidelineTextWithLink,
  StyledGuidelineTextTitle,
  StyledGuidelineTextList,
  StyledGuidelineTextListItem,
} from './styled'

const Guidelines = () => (
  <StyledGuidelines>
    <StyledGuideline>
      <StyledGuidelineTitle>Community guidelines</StyledGuidelineTitle>
      <StyledGuidelineText>
        Zoe is an LBTQ+ inclusive community that celebrates authenticity and
        diversity. Team Zoe is powered by diversity and love and we’re doing
        everything in our power to build and maintain a kind, inclusive, safe
        and fun environment for everyone. We’re not just passionate about our
        app, but also everything that serves LGBTQ+ community and builds a
        better world for all of us.
      </StyledGuidelineText>
      <StyledGuidelineText>
        We encourage you to have fun by being your kind, authentic self. And if
        you ever come across a profile that goes against our values or you ever
        feel unsafe, you can always reach us.
      </StyledGuidelineText>
    </StyledGuideline>

    <StyledGuideline>
      <StyledGuidelineTitle id="minors">Minors</StyledGuidelineTitle>
      <StyledGuidelineText>
        We're 18 plus, so no minors are allowed. Please note that Google Play
        and App Store have their own age rankings which differ from ours.
      </StyledGuidelineText>
    </StyledGuideline>

    <StyledGuideline>
      <StyledGuidelineTitle id="photos-other-user-generated-content">
        Photos and Other User Generated Content
      </StyledGuidelineTitle>
      <StyledGuidelineText>
        Please respect our content rules to avoid being banned.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <span role="img" aria-label="checkmark">
          ✅
        </span>{' '}
        At least one face photo is mandatory in your public photos.
      </StyledGuidelineText>
      <StyledGuidelineText>
        Your public photos and profile information should be free of:
      </StyledGuidelineText>
      <StyledGuidelineTextList>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Nudity (No exposed genitals or anus) on public photos.
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Pornography (This includes images depicting pornographic sex acts,
          real or mimicked, clothed or unclothed, alone or with others) on
          public photos.
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Explicit, suggestive content on public photos. Explicit content is
          allowed in chat and After Match Photos - if agreed with the match.
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Weapons
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Animal corpses
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Illegal activities (No drugs, sexual exploitation or child
          exploitation or abuse) - if you see such things, please report them to
          us and to your local authorities.
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Depiction of violence
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Depiction of self-harm or suicide (If you need help regarding these
          issues, please visit{' '}
          <a
            href="https://findahelpline.com/)"
            target="_blank"
            rel="noopener noreferrer"
          >
            https://findahelpline.com/
          </a>
          )
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Personal info (in bio and photos)
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Sales, spam, ads in either bio, photos or chat
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Minors on photos (no content that features minors under the age of 18
          can be uploaded)
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Celebrity photos
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Junk images
        </StyledGuidelineTextListItem>
      </StyledGuidelineTextList>
      <StyledGuidelineText>
        <span role="img" aria-label="bulb">
          💡
        </span>{' '}
        If you notice anyone violating these rules, make sure to report it to
        us.
      </StyledGuidelineText>
    </StyledGuideline>

    <StyledGuideline>
      <StyledGuidelineTitle id="harassment">Harassment</StyledGuidelineTitle>
      <StyledGuidelineText>
        For yours and the safety of others, please refrain from the following
        activities:
      </StyledGuidelineText>
      <StyledGuidelineTextList>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Promotion (self-promotion, ads, asking for follows or votes, no Ponzi
          schemes, no fitness trainers searching for clients)
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Surveys
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Solicitation (massage services, escort services, cam shows, Venmo or
          PayPal links, sugar daddy/baby arrangements, Amazon or other wishlist
          links, or direct links or requests for donations)
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Exchange of money (Sending money online to someone you have never met)
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Spreading fake news
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Impersonation
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Catfish, scams
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Sextortion
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Links in bio
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Social handles other than IG, Twitter, Snapchat, Tiktok
        </StyledGuidelineTextListItem>
      </StyledGuidelineTextList>
      <StyledGuidelineText>
        <span role="img" aria-label="bulb">
          💡
        </span>{' '}
        Please read our safety tips and be diligent at all times. If you notice
        one of these activities or suspect anything similar, make sure to report
        it to us immediately.
      </StyledGuidelineText>
      <StyledGuidelineText>
        To make this space safe for everyone, we will not tolerate:
      </StyledGuidelineText>
      <StyledGuidelineTextList>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Bullying
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Violence
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Offensive content
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Mental abuse
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Cyber-bullying
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Hate speech
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="cross">
            ✖️
          </span>{' '}
          Discrimination of any kind
        </StyledGuidelineTextListItem>
      </StyledGuidelineTextList>
      <StyledGuidelineText>
        <span role="img" aria-label="bulb">
          💡
        </span>{' '}
        If you notice anyone violating these rules, make sure to report it to
        us.
      </StyledGuidelineText>
    </StyledGuideline>

    <StyledGuideline>
      <StyledGuidelineTitle id="unicorn-hunting">
        Unicorn Hunting
      </StyledGuidelineTitle>
      <StyledGuidelineText>
        This is a safe place for LBTQ+ people only. Hence, this is no place for
        unicorn hunting or similar activities. Cis men or profiles of hetero
        couples owned and represented by cis men are not welcome here. We have a
        strict verification process to avoid cases like this, but if you notice
        any suspicious behavior, make sure to report it to us immediately. Are
        you a LBTQ+ couple searching for a third? Clearly state it in your
        profile to avoid getting banned.
      </StyledGuidelineText>
    </StyledGuideline>

    <StyledGuideline>
      <StyledGuidelineTitle id="reporting-profile">
        Reporting a Profile
      </StyledGuidelineTitle>
      <StyledGuidelineText>
        Here are your resources on how to report a fake, harassing or profile
        that doesn’t fit our community standards
      </StyledGuidelineText>
      <StyledGuidelineText>
        Encountered a profile violating our guidelines? <br />
        Is somebody looking suspicious? Don't hesitate to report them to us
        immediately.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>There are multiple ways you can report a profile:</strong>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <span role="img" aria-label="pointing-right">
          👉
        </span>{' '}
        From the Swiping Screen <br />
        Tap on the "i" icon to open the detail of the profile > Scroll down to
        "Report User". You can either pick one of the provided reasons for the
        report, or write one yourself.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <span role="img" aria-label="pointing-right">
          👉
        </span>{' '}
        From Chat <br />
        Go to the conversation tab with the user > Select Menu (the three dots)
        in the upper right corner > Pick "Report and Block". You can either pick
        one of the provided reasons for the report, or write one yourself.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <span role="img" aria-label="pointing-right">
          👉
        </span>{' '}
        Via chat with our Admin <br />
        You can message us at any time. Go to the chat tab and find the
        conversation with our admin. Please add a screenshot of the troublesome
        profile to help identify the user you want to report.
      </StyledGuidelineText>
      <StyledGuidelineTextWithLink>
        <span role="img" aria-label="pointing-right">
          👉
        </span>{' '}
        Via email helpdesk <br />
        You can reach us at <a href="mailto:anna@zoeapp.co">anna@zoeapp.co</a>
      </StyledGuidelineTextWithLink>
    </StyledGuideline>

    <StyledGuideline>
      <StyledGuidelineTitle id="dating-safety-tips">
        Dating Safety Tips
      </StyledGuidelineTitle>
      <StyledGuidelineText>
        You should always be cautious when interacting with someone you don’t
        know well. Our moderation team does everything in their power to ensure
        a safe environment for everyone using the app, and despite the
        verification protocols we have in place, we advise certain precautions
        you should take while meeting people online. You can’t control the
        actions of others, but you can put your safety first whether you are
        just exchanging messages or meeting in person.
      </StyledGuidelineText>
      <StyledGuidelineTextTitle>Online Safety</StyledGuidelineTextTitle>
      <StyledGuidelineText>
        <strong>Protect Your Personal Information</strong> <br />
        Never share personal information, such as your social security number,
        phone number, home or work address, or details about your daily routine
        (e.g., that you go to certain places, etc.) with people you don’t know.
        If you are a parent, limit the information that you share about your
        children on your profile and in early communications. Avoid sharing
        details such as your children’s names, where they go to school, or their
        ages or genders.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Stay in the App</strong> <br />
        Keep conversations in the app while you’re getting to know someone.
        Users with bad intentions often try to move the conversation to phone,
        text, messaging apps, email, or any other communication channel as soon
        as possible.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>
          Never Send Money or Share Sensitive Financial Information
        </strong>{' '}
        <br />
        Never send money (online or offline, via banks, cash apps or credit
        cards), even if the person claims to be in an emergency. Never share
        information that could be used to access your financial accounts. If
        another user asks you for money, report it to us immediately. Also,
        watch out for crypto or investment scams and Ponzi schemes.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>
          Don’t Let Anyone Make You Feel Bad for Wanting to Play Safe
        </strong>{' '}
        <br />
        If a person you’re getting to know tries to harass or bully you if you
        want to play it safe, consider it as a huge red flag and make sure to
        report it to us immediately. If you don’t want to share some information
        about yourself, you don’t have to. Anyone not respecting your decision
        to stay on the safe side is violating our community guidelines.
      </StyledGuidelineText>
      <StyledGuidelineTextWithLink>
        <strong>Watch Out for Romance Scams</strong> <br />
        This is not just a thing from the movies, but, unfortunately, a real
        threat. For tips on avoiding romance scams,{' '}
        <a
          href="https://consumer.ftc.gov/articles/what-know-about-romance-scams"
          target="_blank"
          rel="noopener noreferrer"
        >
          check out this link
        </a>
        .
      </StyledGuidelineTextWithLink>
      <StyledGuidelineTextWithLink>
        <strong>Be Wary of Long Distance and Overseas Relationships</strong>{' '}
        <br />
        Watch out for scammers who claim to be stuck somewhere far from their
        country and in need of financial help to return home or anything
        similar.{' '}
        <a
          href="https://www.military.com/spouse/military-life/military-romance-scams-are-you-target.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more here
        </a>
        . If someone is avoiding answering your questions or pushing for a
        serious relationship without meeting or getting to know you first —
        that’s a huge red flag.
      </StyledGuidelineTextWithLink>
      <StyledGuidelineText>
        <strong>Report All Suspicious and Offensive Behavior</strong> <br />
        If we work together, we’ll make this community safer for everyone.
        Please block and report anyone that violates our terms. Here are some
        examples of violations:
      </StyledGuidelineText>
      <StyledGuidelineTextList>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="pointing-right">
            👉
          </span>{' '}
          Requests for money or donations
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="pointing-right">
            👉
          </span>{' '}
          Underage users
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="pointing-right">
            👉
          </span>{' '}
          Harassment, threats, and offensive messages
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="pointing-right">
            👉
          </span>{' '}
          Inappropriate or harmful behavior during or after meeting in person
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="pointing-right">
            👉
          </span>{' '}
          Fraudulent profiles
        </StyledGuidelineTextListItem>
        <StyledGuidelineTextListItem>
          <span role="img" aria-label="pointing-right">
            👉
          </span>{' '}
          Spam or solicitation including links to commercial websites or
          attempts to sell products or services
        </StyledGuidelineTextListItem>
      </StyledGuidelineTextList>
      <StyledGuidelineTextWithLink>
        <strong>Beware of Sextortion</strong> <br />
        Never share nudes with your face on them to prevent sextortion. Read
        more about it{' '}
        <a
          href="https://www.fbi.gov/video-repository/newss-what-is-sextortion/view"
          target="_blank"
          rel="noopener noreferrer"
        >
          here
        </a>
        .
      </StyledGuidelineTextWithLink>
      <StyledGuidelineText>
        <strong>Pay Attention to Verification Badge</strong> <br />
        Pay attention to whether the profile you’re interacting with is verified
        or not, and report any suspicious behavior to us. Even if the user is
        verified, don’t hesitate to request more proof that they’re authentic.
        Even if the profile is verified and something feels off, report it
        anyway.
      </StyledGuidelineText>
      <StyledGuidelineText>
        On another note, despite our security measures, identity theft through
        the creation of falsified (fake profiles) may still occur. If anything
        feels off, report the profile immediately, even if it’s a verified one.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>In-chat Confirmation</strong> <br />
        To further your safety, Zoe provides a mechanism which allows users to
        verify each other via the In-chat Confirmation feature. We encourage you
        to initiate the mutual confirmation with your every match, even if they
        have a verification badge.
      </StyledGuidelineText>
      <StyledGuidelineTextTitle>
        Safety Tips for Meeting in Person
      </StyledGuidelineTextTitle>
      <StyledGuidelineText>
        <strong>Don’t Rush It</strong> <br />
        Take your time and get to know the other person before agreeing to meet
        or chat off the app. Don’t be afraid to ask questions to screen for any
        red flags or personal dealbreakers. A phone or video call can be a
        useful screening tool before meeting.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Meet & Stay in Public</strong> <br />
        The best time for a first date is usually while there’s daylight. Meet
        for the first few times in a populated, public and known place — never
        at your home, your date’s home, or any other private location. If your
        date pressures you to go to a private location, end the date. Make sure
        to always let someone close know where you are (and ready to pick you up
        just in case) and have your cell phone charged and with you at all
        times.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Stay Sober & Hold Your Ground</strong> <br />
        Be aware of the effects of drugs or alcohol on you specifically — they
        can impair your judgement and your alertness. If your date tries to
        pressure you to use drugs or drink more than you’re comfortable with,
        know your limits and end the date. Reject any activity that may endanger
        your physical or mental well-being.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Don’t Leave Drinks or Personal Items Unattended</strong> <br />
        Know where your drink comes from and know where it is at all times. Only
        accept drinks poured or served directly from the bartender or server.
        Many substances that are slipped into drinks to facilitate sexual
        assault are odorless, colorless, and tasteless. Also, keep your phone,
        purse, wallet, and anything containing personal information on you at
        all times.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>If You Feel Uncomfortable, Leave</strong> <br />
        It’s more than okay to end the date early or abruptly if you’re feeling
        uncomfortable, or your limits are breached. Even if your instincts are
        telling you something is off or you feel unsafe, ask the bartender or
        server for help. If your safety is compromised to the point it may not
        be easy for you to leave, reach out to others for help - for this, make
        sure your phone is charged and with you at all times.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Consent & Protection</strong> <br />
        Even before it comes to sexual intercourse, remember to communicate the
        consent or lack of it, use protection, and be open about your
        boundaries. Remember to protect yourself from STDs. If you’re sexually
        active, get tested regularly, and ask your partner if they do so as
        well.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Stopping Unwanted Contacts or Harassment</strong> <br />
        When the other person won’t take “no” for an answer, breaking all means
        of communication is the safest approach. If things cross the line,
        report it to local authorities.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>LGBTQ+ Travel</strong> <br />
        Be careful while traveling. Though we know the importance of being
        open-minded and inclusive of all gender identities and sexual
        orientations, some countries bear a higher potential risk for LGBTQ+
        people.
      </StyledGuidelineText>
      <StyledGuidelineText>
        It’s very important to check out the laws and exercise extra caution if
        you choose to connect with new people in these countries - as some law
        enforcement have been known to use dating apps as tools for potential
        entrapment. Some countries have also recently introduced laws that
        criminalize communications between individuals on same-sex dating
        applications or websites and even aggravate penalties if that
        communication leads to sexual encounters.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Resources for Help, Support, or Advice</strong> <br />
        Remember that no method of risk reduction is perfect. If you have a
        negative experience, please know that it is not your fault and help is
        available. Report any incidents and consider reaching out to one of the
        resources below. If you feel you are in immediate danger or need
        emergency assistance, call 911 (U.S. or Canada) or your local law
        enforcement agency.
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>RAINN’s National Sexual Assault Hotline</strong> <br />
        1-800-656-HOPE (4673) |{' '}
        <a
          href="https://hotline.rainn.org/online"
          target="_blank"
          rel="noopener noreferrer"
        >
          online.rainn.org
        </a>{' '}
        |{' '}
        <a
          href="https://www.rainn.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.rainn.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Planned Parenthood</strong> <br />
        1-800-230-7526 |{' '}
        <a
          href="https://www.plannedparenthood.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.plannedparenthood.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>National Domestic Violence Hotline</strong> <br />
        1-800-799-SAFE (7233) or 1-800-787-3224 |{' '}
        <a
          href="https://www.thehotline.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.thehotline.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>National Human Trafficking Hotline</strong> <br />
        1-888-373-7888 or text 233733 |{' '}
        <a
          href="https://humantraffickinghotline.org/en"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.humantraffickinghotline.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>National Sexual Violence Resource Center</strong> <br />
        1-877-739-3895 |{' '}
        <a
          href="https://www.nsvrc.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.nsvrc.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>National Center for Missing & Exploited Children</strong> <br />
        1-800-THE-LOST (843-5678) |{' '}
        <a
          href="https://www.missingkids.org/gethelpnow/cybertipline"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.cybertipline.com
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Cyber Civil Rights Initiative</strong> <br />
        1-844-878-2274 |{' '}
        <a
          href="https://cybercivilrights.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.cybercivilrights.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>VictimConnect - Crime Victim Resource Center</strong> <br />
        1-855-4VICTIM (855-484-2846) |{' '}
        <a
          href="https://victimconnect.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.victimconnect.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>FBI Internet Crime Complaint Center</strong> <br />
        <a
          href="https://www.ic3.gov/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.ic3.gov
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>LGBT National Help Center</strong> <br />
        1-888-843-4564 |{' '}
        <a
          href="http://www.lgbtnationalhelpcenter.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.lgbtnationalhelpcenter.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Trans Lifeline</strong> <br />
        1-877-565-8860 (US) or 1-877-330-6366 (CA) |{' '}
        <a
          href="https://translifeline.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.translifeline.org
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>The Trevor Project</strong> <br />
        <strong>LGBTQ+ Hotline Available 24/7</strong> <br />
        1-866-488-7386 |{' '}
        <a
          href="https://www.thetrevorproject.org/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://www.thetrevorproject.org/
        </a>
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>LGBTQ+ National Hotline</strong> <br />
        <strong>LGBTQ+ Hotline for ages 23 and under</strong> <br />
        800-246-7743 (Mondays to Fridays from 1 pm – 9 pm PST; Saturday from 9
        am – 2 pm PST)
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>LGBTQ+ Hotline for all ages</strong> <br />
        888-843-4564 (Mondays to Fridays from 1 pm – 9 pm PST; Saturday from 9
        am – 2 pm PST)
      </StyledGuidelineText>
      <StyledGuidelineText>
        <strong>Pride Institute LGBTQ Dependency</strong> <br />
        <strong>Substance Dependency Organization</strong> <br />
        (800)-547-7433 |{' '}
        <a
          href="https://pride-institute.com/lgbtq-recovery-programs//"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://pride-institute.com/lgbtq-recovery-programs/
        </a>
      </StyledGuidelineText>
      <StyledGuidelineTextWithLink>
        <span role="img" aria-label="e-mail">
          📧
        </span>{' '}
        Zoe Email support contact:{' '}
        <a href="mailto:anna@zoeapp.co">anna@zoeapp.co</a>
      </StyledGuidelineTextWithLink>
      <StyledGuidelineTextWithLink>
        <span role="img" aria-label="e-mail">
          📧
        </span>{' '}
        Zoe Data Protection Officer contact:{' '}
        <a href="mailto:dpo@cosmiclatte.com">dpo@cosmiclatte.com</a>
      </StyledGuidelineTextWithLink>
      <StyledGuidelineTextWithLink>
        <span role="img" aria-label="bulb">
          💡
        </span>{' '}
        Here you can read our <Link to="/terms-of-use?web">Term of use</Link> &{' '}
        <Link to="/privacy-policy?web">Privacy policy</Link>
      </StyledGuidelineTextWithLink>
    </StyledGuideline>
  </StyledGuidelines>
)

export default Guidelines
