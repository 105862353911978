import React from 'react'
import Props from 'prop-types'

import { isWeb } from 'common/helpers'
import Page from 'components/Page'
import Navigation from 'components/Navigation'
import Container from 'components/Styled/Container'
import CommunityGuidelinesPage from 'components/CommunityGuidelines'
import { PageContent } from 'components/Styled/Layout'
import CommunityGuidelinesHero from 'components/CommunityGuidelines/CommunityGuidelinesHero'
import {
  StyledHeader,
  StyledImage,
} from 'components/CommunityGuidelines/styled'
import HeroImage from 'components/CommunityGuidelines/assets/community-guidelines-hero.webp'

const CommunityGuidelines = ({ pageContext: lang }) => {
  const rawQueryParams = typeof location !== 'undefined' ? location.search : ''

  return (
    <Page title="Community Guidelines" translates={lang.lang}>
      <StyledHeader>
        <Navigation
          isMobileVisible={isWeb(rawQueryParams)}
          absolute
          translates={lang.lang}
        />
        <CommunityGuidelinesHero />
        <StyledImage src={HeroImage} alt="Guidelines illustration" />
      </StyledHeader>
      <Container>
        <PageContent>
          <CommunityGuidelinesPage />
        </PageContent>
      </Container>
    </Page>
  )
}

CommunityGuidelines.propTypes = {
  location: Props.shape({
    search: Props.string,
  }).isRequired,
  pageContext: Props.any.isRequired,
}

export default CommunityGuidelines
