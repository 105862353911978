import React from 'react'
import { StyledContainer, StyledHeading } from './styled'

const CommunityGuidelinesHero = () => (
  <StyledContainer>
    <StyledHeading>Community Guidelines</StyledHeading>
  </StyledContainer>
)

export default CommunityGuidelinesHero
