import styled from 'styled-components'
import { theme } from 'common/styles'

const StyledHeader = styled.header`
  position: relative;
  display: grid;
  align-content: start;
  height: 52.1rem;
  width: 100%;
  max-width: 144rem;
  color: white;
  margin: 0 auto;
  background-color: ${theme.colors.primary};
  box-shadow: 0 0 0 100vmax ${theme.colors.primary};
  clip-path: inset(0 -100vmax);
`

const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  ${theme.mq.large.css`
    flex-direction: row;
    gap: 6.25rem;
  `}
`

const StyledImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 50%;
  max-width: 54.7rem;
  min-width: 30rem;

  ${theme.mq.small.css`
    left: 50%;
    right: 50%;
    transform: translateX(-50%);
    width: 30rem;
  `}
`

export { StyledHeader, StyledContent, StyledImage }
