import styled from 'styled-components'
import { theme } from 'common/styles'

const StyledQuickLinks = styled.div`
  display: grid;
  gap: 2.4rem;

  ${theme.mq.large.css`
    gap: 3.2rem;
    position: sticky;
    top: 4rem;
    width: 20rem;
    align-self: flex-start;
    flex-shrink: 0;
    z-index: 2;
  `}
`

const StyledQuickLinksTitle = styled.span`
  color: #41354d;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
`

const StyledQuickLinksList = styled.ul`
  display: grid;
  gap: 2.4rem;
  list-style: none;
  padding: 0;
  margin: 0;

  ${theme.mq.large.css`
    gap: 3.2rem;
  `}
`

const StyledQuickLinksItem = styled.li``

const StyledQuickLink = styled.a`
  color: #9f2ffe;
  font-size: 1.6rem;
  line-height: 1.9rem;
  text-decoration: none;

  ${theme.mq.large.css`
    font-size: 1.8rem;
    line-height: 2.1rem;
  `}
`

export {
  StyledQuickLinks,
  StyledQuickLinksTitle,
  StyledQuickLinksList,
  StyledQuickLinksItem,
  StyledQuickLink,
}
